.loginContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
    background-color: #ffffff;
}

.titleContainer {
    display: flex;
    flex-direction: row; 
    flex: 1;
    align-items: center;
}
.loginText {
    font-size: 18px;
    color:#4ab575;
    line-height: 27px;
    font-family: sans-serif;
    font-weight: 800;
    letter-spacing: -0.13px;
}
.titleText {
    opacity: 0.8;
    color: #172630;
    font-family: sans-serif;
    font-size: 16px;
    letter-spacing: -0.09px;
    line-height: 24px;
}
.leftContainer1 {
    display: flex;
    flex: 0.5;
    height: 100%;
}
.rightContainer1{
    display: flex;
    flex: 0.5;
    justify-content: center;
    height: 50%;
}
.imageContainer {
    display: flex;
    flex: 0.5;
    align-items: center;
    justify-content: center;
}
.titleNameContainer {
    display: flex;
    flex: 9.5;
    align-items: center;
    justify-content: flex-start;
}

.subTitleContainer {
    display: flex;
    flex-direction: row; 
    flex: 2;
    align-items: flex-start;
    justify-content: flex-start;
}

.bodyContainer {
    display: flex;
    flex-direction: row; 
    flex: 7;
    align-items: center;
    justify-content: center; 
}

.formContainer{
    display: flex;
    flex: 10;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.inputContainer{
    display: flex;
    flex:6;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.input-login{
    height: 55px;	
    width: 407px;	
    border-radius: 123px;	
    background-color: #EBEBEB;
    color: #272727;	
    font-family: sans-serif;	
    font-size: 16px;	
    font-weight: 800;	
    letter-spacing: -0.09px;	
    line-height: 18px;
    border:none;
    padding-Left: 20px;
}
.loginSelectContainer{
    height: 55px;	
    width: 430px;	
    border-radius: 123px;	
    background-color: #EBEBEB;
    color: #272727;	
    font-family: sans-serif;	
    font-size: 16px;	
    font-weight: 800;	
    letter-spacing: -0.09px;	
    line-height: 18px;
    border:none;
    padding-Left: 20px;
    background: url('../images/dropDownRight.png') no-repeat right #ddd;
    -webkit-appearance: none;
    background-position-x: 390px;
}
input.middle:focus {
    outline-width: 0;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
}
.button{
    height:48px;
    width: 200px;
    border-radius: 24px !important;
    background-color: #4ab575 !important;
    border-color: transparent !important;
    box-shadow: 0 2px 4px 0 rgba(110,110,110,0.5);
    text-align: center;
    cursor: pointer;
}
.button > p {
    line-height: 18px;
    width: auto;
    display: block;
    opacity: 0.81;
    color:#fff;
    font-size:16px;
    text-align: center;
}

.footerContainer{
    display: flex;
    flex: 5;
    align-items: center;
    justify-content: center;
}
.forgetPasswordText {
    opacity: 0.5;
    color: #272727;
    font-family: sans-serif;
    font-size: 16px;
    letter-spacing: -0.09px;
    line-height: 18px;
}

.selectionContainer{
    display: flex;
    flex-direction: row;
    width: 30%;
    margin-bottom: 30px;
    background: #FAFAFA;
    border: 1px solid #DAD7D7;
    box-sizing: border-box;
    border-radius: 10px;
    height: 48px;
}
.selectionBodyContainer{
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: space-around;
}