.generalBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: #F2F2F2;
    padding-left: 25px;
    /* padding-right: 25px; */
    padding-top: 70px;
    padding-Bottom: 30px;
}
.formCard {
    display: flex;
    width: 97%;
    padding-left: 25px;
    padding-top: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    background: #FFFFFF;
    border: 1px solid #E1DDDD;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.formCard1 {
    display: flex;
    flex-direction: column;
    width: 97%;
    padding-left: 25px;
    padding-top: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    background: #FFFFFF;
    border: 1px solid #E1DDDD;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.flexDirection{
    display: flex;
    flex-direction: row;
}
.leftFormCard1 {
    /* flex: 0.4; */
    flex-direction: column;
    justify-content: space-between;
    top: 80px;
    width: 33%;
    padding-right: 20px;
}
.middleFormCard1{
    /* flex: 0.33; */
    flex-direction: column;
    justify-content: space-between;
    top: 80px;
    width: 33%;
    padding-right: 20px;
}
.rightFormCard1 {
    /* display: flex;
    flex: 0.33; */
    flex-direction: column;
    justify-content: space-between;
    width: 33%;
    padding-right: 20px;
}
.leftFormCard {
    /* display: flex; */
    flex: 5;
    flex-direction: column;
    justify-content: space-between;
    top: 80px;
    width: 50%;
}
.rightFormCard {
    display: flex;
    flex: 5;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
}
.formWrapper{
    display: flex; 
    flex-direction: row;
}
@media only screen and (max-width: 1200px) {
    .formWrapper{
        display: flex; 
        flex-direction: column;
    }
    .generalBody {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background: #F2F2F2;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 70px;
        padding-Bottom: 30px;
    }
    .formCard {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 97%;
        padding-left: 25px;
        padding-top: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
        background: #FFFFFF;
        border: 1px solid #E1DDDD;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }
    .formCard1 {
        display: flex;
        width: 97%;
        padding-left: 25px;
        padding-top: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
        background: #FFFFFF;
        border: 1px solid #E1DDDD;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }
    .leftFormCard {
        display: flex;
        flex: 5;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        top: 80px;
        width: 100%;
    }
    .rightFormCard {
        display: flex;
        flex: 5;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
    .flexDirection {
        display: flex !important;
        flex-direction: column !important;
    } 
}
.formContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.formTitle {
    display: flex;
    width: 85%;
    padding-top: 40px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(0,0,0,0.2);
    align-items: center;
    justify-content: flex-start;
}
.formContainerBody{
    padding-top: 15px;
    display: flex;
    flex: 8;
    width: 85%;
    flex-direction: column;
    /* border-bottom: 2px solid rgba(0,0,0,0.2); */
    /* align-items: flex-start; */
    justify-content: flex-start;
}

@media only screen and (max-width: 1200px) {
    .leftFormCard1 {
        flex-direction: column;
        justify-content: space-between;
        top: 80px;
        width: 100%;
        padding-right: 20px;
    }
    .middleFormCard1{
        flex-direction: column;
        justify-content: space-between;
        top: 80px;
        width: 100%;
        padding-right: 20px;
    }
    .rightFormCard1 {
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding-right: 20px;
    }
    .leftFormCard {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        top: 80px;
        width: 100%;
    }
    .rightFormCard {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
}