.cardContainer1{
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: #FFFFFF;
    height: 180px;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2), 0 3px 8px 0 rgba(0,0,0,0.1);
    width: 166px;
    margin-right: 14px;
    margin-top: 16px;
    margin-right: 16px;
    cursor: pointer;
}
.cropImage{
    height: 121px;
    width: 150px;
    margin: 8px 8px 7.35px 8px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #E0E0E0;
    align-items: center;
    justify-content: center;
    display: flex;
}
.cardNameContainer1{
    display: flex;
    align-items: center;
    justify-content: center;
}
.idContainer{
    display: flex;
    width: 30px;
    height: 30px;
    position: absolute;
    background-color: #FFFFFF;
    /* z-index: 1000; */
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    left: 82%;
}
.idSelectedContainer{
    display: flex;
    width: 40px;
    height: 40px;
    position: absolute;
    background-color: #4CBA6A    ;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    left: 83%;
    top: -4%;
}
