.landingContainer{
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100vh;
    background-color: #FFFFFF;
}
.leftContainer {
    display: flex;
    flex: 2;
    align-items: flex-start;
    justify-content: flex-start;
}

.rightContainer12 {
    display: flex;
    flex: 1.5;
    flex-direction: column;
    /* padding-right: 25px; */
}

.emptyContainer {
    display: flex;
    flex: 0.25;
}

@media only screen and (max-width: 900px) {
    .leftContainer {
        display: none;
        flex: 0.1;
        align-items: flex-start;
        justify-content: flex-start;
        width:0%;
    }
    .landingImage{
        /* display: none; */
        width: 100%;
    }
    .rightContainer12 {
        display: flex;
        flex: 2;
        flex-direction: column;
        width: 100%;
    }
    .headerContainer {
        display: flex;
        flex: 1;
        /* margin-top: 10px; */
        /* padding-left: 20px; */
        padding-bottom: 10px;
        border-bottom: 1px solid #EBEBEB;
    }
  }

.headerContainer {
    display: flex;
    flex: 1;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EBEBEB;
    margin-right: 20px;
}
.dateContainer{
    display: flex;
    flex: 4;
    align-items: center;
    justify-content: flex-start;
}
.date{
    padding: 0px;
    color: #20323F;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 30px;
}
.userImageContainer{
   padding-right: 10px;
}
.userImage{
    border-radius: 50%;
    object-fit: cover;
}
.userContainer{
    display: flex;
    flex: 4;
    flex-direction: row;
}
.usernameContainer {
    display: flex;
    flex: 7;
    align-items: center;
    justify-content: flex-end;
    border-right: 2px solid #EBEBEB;
    padding-right: 10px;
    margin-top: 26px;
    height: 30px;
}
.logoutContainer {
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #EBEBEB;
}

.languageContainer{
    display: flex;
    flex: 1.8;
    align-items: center;
    justify-content: space-around;
    margin-top: 26px;
    height: 30px;
}

.selectedLangauge {
    position: absolute;
    width: 21px;
    height: 19px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    color: #20323F;
}

.titleContainer {
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: center;
}
.menuContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 5;
    align-items: flex-start;
    justify-content: left;
}
.organizationContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 5;
    align-items: center;
    justify-content: center;
}
.bottomContainer{
    display: flex;
    flex: 3;
}

.cardContainer {
    display: flex;
    width: 25%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
}
.cardContainerBody {
    /* background-color: blue;
    height: 106px; 
    width: 106px;
    align-items: center;
    justify-content: center;
    border-radius: 20px; */
    position: relative;
}
.iconContainer{
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
} 
.iconContainer1{
    position: absolute;
    top: 20px; left: 10px; bottom: 0; right: 0;
} 
.iconContainer2{
    position: absolute;
    top: 32px; left: 220px; bottom: 0; right: 0;
} 
.cardContainerName {
    display: flex;
    flex: 4;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #20323F;
    font-family: sans-serif;
    font-size: 16px;
}

.toogleLangauge {
    display: flex;
    width: 15%;
    flex-direction: column;
    position: absolute;
    top: 60px;
    z-index: 1000;
    cursor: pointer;
    background-color: #FFFFFF;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2), 0 3px 8px 0 rgba(0,0,0,0.1);
}
.toogleLogout{
    display: flex;
    width: 15%;
    flex-direction: column;
    position: absolute;
    top: 60px;
    z-index: 1000;
    cursor: pointer;
    background-color: #FFFFFF;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2), 0 3px 8px 0 rgba(0,0,0,0.1);
    align-items: center;
    justify-content: center;
}
.languageToogleContainer{
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-left: 5px;
}
.languageLeftDropDown{
    display: flex;
    flex: 3;
    align-items: center;
    justify-content: flex-start;
    font-family: sans-serif;
    font-size: 17px;
}
.languageRightDropDown {
    display: flex;
    flex: 7;
    align-items: center;
    justify-content: flex-start;
}
.pointer {
    cursor: pointer;
}
.active {
    background-color: #F2F7FA;
}