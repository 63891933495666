.generalBodyContainer{
    display: flex;
    flex: 1;
    flex-direction: column;
}
.customButtonContainer{
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.customButtonLeft{
    display: flex;
    flex: 3;
}
.customButtonRight{
    display: flex;
    flex: 7;
    align-items: center;
    justify-content: flex-start;
}

.tableContainer{
    margin-top: 30px;
}
.tableContainer1{
    margin-top: 15px;
}
.boldContent{
    font-weight: bold !important;
}