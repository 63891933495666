.seasonContainer{
    display: flex;
    flex-direction: row;
}
.leftSeasonContainer{
    display: flex;
    flex: 2
}
.rightSeasonContainer{
    display: flex;
    flex: 8;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: space-evenly; */
}