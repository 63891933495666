.cropContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #EBEBEB;
    margin-top: 10px;
}
.cropBodyContainer{
    display: flex;
    flex-direction: column;
    padding-left: 4%;
    margin-top: 20px;
    align-items: flex-start;
    justify-content: space-around;
}
.cropBody{
    display: flex;
    flex: 9.5;
    flex-wrap: wrap;
    /* height: 92vh; */
    flex-direction: row;
    background-color: #EBEBEB;
    /* padding-left: 4%; */
    align-items: center;
    /* justify-content: flex-start; */
    justify-content: center;
    margin-bottom: 20px;
}


.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 10px;
  }
  

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  
  .container input:checked ~ .checkmark {
    background-color: #4CBA6A;
  }
  
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  

  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

.radioContainer {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radioCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.radioContainer:hover input ~ .radioCheckmark {
  background-color: #ccc;
}

.radioContainer input:checked ~ .radioCheckmark {
  background-color:#4CBA6A;
}

.radioCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radioContainer input:checked ~ .radioCheckmark:after {
  display: block;
}

.radioContainer .radioCheckmark:after {
 	top: 6px;
	left: 6px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

.selected {
  color:#4CBA6A !important;
}