.customButton{
    display: flex;
    //flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #4AB575;
    border-radius: 25px;    
    border-radius: 5px;
    margin-right: 10px;
    width: 45%;
    padding: 10px;
    cursor: pointer;
    .buttonImageContainer{
        display: flex;
        // flex: 2;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
    }
    .buttonTextContainer{
        display: flex;
        flex: 7;
        align-items: center;
        justify-content: center;
        font-family: Asap;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: white;
    }
  }

  .customButton:link{
    text-decoration: none;
    color: white !important;
  }

  .leftFertilizerContainer{
    display: flex;
    flex: 4;
    padding-right: 5px;
  }

  .rightFertilizerContainer3{
    display: flex;
    flex: 6;
  }

  .rightFertilizerContainer{
    display: flex;
    flex-direction: row;
    flex: 6;
    align-items: flex-start;
  }

  .spinnerContainer{
    position: absolute;
    right: 50%;
    left: 50%;
    top: 50%;
    bottom: 50%;
    .spinner{
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #4AB575;;
      width: 60px;
      height: 60px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
  }
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .disabled{
    border: none;
    background-color: transparent;
  }

  .flexColumn{
    display: flex;
    flex-direction: column;
  }
  .flexRow{
    display: flex;
    flex-direction: row;
  }

  .customButton1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #4AB575;
    border-radius: 25px;    
    border-radius: 5px;
    margin-right: 10px;
    width: 25%;
    padding: 10px;
    margin-left: 10%;
    cursor: pointer;
    .buttonImageContainer{
        display: flex;
        // flex: 2;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
    }
    .buttonTextContainer{
        display: flex;
        flex: 7;
        align-items: center;
        justify-content: center;
        font-family: Asap;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: white;
    }
  }

  .customButton2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #4AB575;
    border-radius: 25px;    
    border-radius: 5px;
    width: 70%;
    padding: 10px;
    margin-right: 10%;
    cursor: pointer;
    .buttonImageContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
    }
    .buttonTextContainer{
        display: flex;
        flex: 7;
        align-items: center;
        justify-content: center;
        font-family: Asap;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: white;
    }
  }

  .published{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #4CBA6A;
    position: absolute;
    z-index: 1000;
    top: 16%;
    left: 90px;
  }

  .selectedCropMainContainer{
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 20px;
    padding-right: 10px;
    // position: -webkit-sticky;
    // position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000;
    padding-bottom: 5px;
    .selectedCropContainer{
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      .selectedImageContainer{
        display: flex;
        flex-direction: column;
        margin: 5px 5px 5px 5px;
        align-items: center;
        justify-self: center;
      }
      .cropImage{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }
      .emptyImage{
        background-color: #E0E0E0;
      }
      .cropName{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0;
        margin-top: 3px;
        padding-bottom: 5px;
      }
    }
}