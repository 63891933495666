@import url("https://fonts.googleapis.com/css?family=Asap");
@import url("https://fonts.googleapis.com/css?family=Pacifico");
html,body{
    background:#fff;
    font-family: Asap;
    color:#172630;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex:1;
    flex-grow:1;
    height: 100%;
    background: #F2F2F2;
}
#root, .App{
    display: flex;
    flex:1;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}
.Toastify__toast--success {
    background-color: #4AB575;
}


