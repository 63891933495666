.activityContainer{
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 25px;
    .leftOffset{
        display: flex;
        flex: 2;
    }
    .leftOffset1{
        display: flex;
        flex: 2.10;  
    }
    .mainContent1{
        display: flex;
        flex: 8;
        flex-direction: column;  
        //background: #FAFAFA;
    }
    .mainContent{
        display: flex;
        flex: 8;
        flex-direction: column;  
        background: #FAFAFA;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        padding: 20px;
    }
}

.flexDirectionColumn{
    display: flex;
    flex: 1;
    flex-direction: column;
}

.fomiContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.fertilizerFomi{
    display: flex;
    //flex: 1;
    flex-direction: row;
    margin-bottom: 10px;
    margin-top: 20px;
    .leftFertilizerFomi {
        display: flex;
        flex: 2;
        flex-direction: column;
        align-items: flex-start;
        justify-items: flex-start;
        .leftFertilizerFomiText1{
            height: 20px;
        }
        .leftFertilizerFomiText{
            height: 50px;
        }
    }
    .rightFertilizerFomi{
        display: flex;
        flex: 8;
        flex-direction: column;
        align-items: flex-start;
        justify-items: flex-start;
        .fomiTextBoxContainer{
            display: flex;
            flex-direction: row;
        }
    }
}

.scrollableWrapper{
    position: relative;
    .scrollableScrollable{
        margin-left: 141px;
        overflow-x: scroll;
        overflow-y: visible;
        padding-bottom: 5px;
        width: 300px;
        .tableContainer .stickyCol {
            left: 0;
            position: absolute;
            top: auto;
        }
    }
}

.leftSelectLabelContainer1{
    display: flex;
    flex: 2;
}
.rightSelectLabelContainer1{
    display: flex;
    flex: 8;
}
.leftSelectLabelContainer2{
    display: flex;
    flex: 4;
}
.rightSelectLabelContainer2{
    display: flex;
    flex: 6;
}
.offset{
    display: flex;
    flex: 0.5;
}
.selectedRow{
    background-color:#4ab575;
}

.modalBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .leftModal {
        display: flex;
        flex: 0.5;
    }
    .rightmodal {
        display: flex;
        flex: 0.5;
    }
    .modalButton{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4CBA6A;
        color: #FFFFFF;
        width: 50px;
        height: 30px;
        border-radius: 5px;
    }
}

.mainActivityContainer {
    display: flex;
    flex: 10;
    flex-direction: column;
    background-color: #ffffff;
    padding-top: 30px;
    .activityHeader {
        display: flex;
        height: 70px;
        flex-direction: row;
        border-color: #c8c8c8;
        border-bottom-width: 1px;
        padding-top: 5px;
    }
    .activityHeader1 {
        display: flex;
        height: 30px;
        flex-direction: row;
        border-color: #c8c8c8;
        border-bottom-width: 1px;
        padding-top: 5px;
        margin-bottom: -5px;
    }
    .activityLeftPart {
        flex: 0.57;
        display: flex;
        flex-direction: row;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
    }
    .activityRightPart {
        flex: 2;
        display: flex;
        flex-direction: row;
    }
    .activityWeekHeader{
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 0.5px solid #bdbdbd;
        border-right-width: 0.5px;
        border-left-width: 0.5px;
        border-color: #bdbdbd;
    }
    .activityWeekHeader1{
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 0.5px solid #bdbdbd;
        border-right-width: 0.5px;
        border-left-width: 0.5px;
        border-color: #bdbdbd;
    }
    .activityMonth{
        color:#172630;
        text-transform: uppercase;
        font-weight: 700;
        opacity:0.42;
    }
    .activityMonth1{
        color:#172630;
        font-weight: 500;
        opacity:0.42;
        font-size: 12;
    }
    .activityDataRow {
        display: flex;
        flex-direction: row;
        height: 73px;
        flex:1;
        //border: 0.5px solid #ebebeb;
        border-color: #ebebeb;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #dbdbdb;
    }
    .activityBodyContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: #ffffff;
        padding-left: 10px;
    }
    .activityDataCell {
        flex:0.05;
        height:73px;
        justify-content: center;
        border-color:#bdbdbd;
        border-style: solid;
        //border-width: 0.5px;
        border-width: 1px;
        border-radius: 1px;
        position: relative;
    }
    .dataCellActive {
        background-color:#eefcf4;
        border-left-width: 0px;
        border-right-width: 0px;
        border-bottom-width: 0px;
        border-top-width: 0px;
    }
    .timeline {
        background-color: #4cba6a;
        height: 20px;
        margin-left:-2px;
    }
    .timeline1 {
      position: absolute;
      top: 30%;
      left:0px;
      right:0px;
      bottom: 0px;
      background-color: #4cba6a;
      height: 20px; 
      margin-left:-2px
    }
    .startTimeLine{
       border-top-left-radius: 11px;
       border-bottom-left-radius: 11px;
       margin-left: 1px;
       margin-right: -2px;
    }
    .endTimeLine{
        border-top-right-radius: 11px;
        border-bottom-right-radius: 11px;
        margin-right: 1px;
        margin-left: -2px;
    }
    .activityName{
        color: #212121;
        font-family: Asap;	
        font-size: 16;
        letter-spacing: -0.09;
        line-height: 18px;
    }
    .cropName{
        color: #212121;
        font-family: Asap;	
        font-size: 16px;
        letter-spacing: -0.09px;
        line-height: 18px;
     }
    .displayNone{
       display: none;
     }
    .navigationContainer{
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: space-around;
     }
    .thisWeekContainer{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      background-color: #4CBA6A;
      height: 48px;
      width: 121px;
      margin-bottom: 25px;
      margin-right: 15px;
    }
    .thisweektext{
      color: #ffffff;
    }
}

.videoContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .videoPlayerContainer{
        display: flex;
        width: 45%;
        flex-direction: column;
        border-radius: 5px;
        background-color: #F7F7F7;
        margin-right: 1%;
        margin-bottom: 47px;
        padding: 17px;
    }
    .videoTitleContainer {
        display: flex;
        flex-direction: row;
        height: 55px;
    }
    .titleTextContainer {
        color: #212121;
        font-family: Asap;
        font-size: 20px;
        font-weight: 500px;
        letter-spacing: -0.11;
        line-height: 23px;
    }
    .descriptionContainer{
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        flex-wrap: wrap;
    }
    .descriptionText{
        color: #212121;	
        font-family: Asap;
        font-size: 16px;
        letter-spacing: -0.09px;
        line-height: 24px;
        text-align: left;
    }
    .videoPlayer{
        display: flex;
        flex-direction: column;
        background-color: #D8D8D8;
        height: 260px;
    }
}
.activityName td{ 
    text-align: left;
    font-weight: bold;
}
.seasonTitle{
    color: #212121;	
    font-family: Asap;
    font-size: 16px;
    letter-spacing: -0.09px;
    line-height: 24px;
    text-align: left;
    margin-top: 20px;
}

.tabHeader{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: -30px;
    cursor: pointer;
    .tabContent{
        padding: 10px;
    }
    .activeTab {
        padding: 10px;
        background-color: #4AB575;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #ffffff;
    }
    .activeFirstTab {
        padding: 10px;
        background-color: #4AB575;
        border-top-left-radius: 10px;
        color: #ffffff;
    }
    .activeMiddleTab {
        padding: 10px;
        background-color: #4AB575;
        color: #ffffff;
    }
    .activeLastTab {
        padding: 10px;
        background-color: #4AB575;
        border-top-right-radius: 10px;
        color: #ffffff;
    }
}

@media only screen and (max-width: 1200px) {
    .leftSelectLabelContainer1{
        display: flex;
        flex: 4;
    }
    .rightSelectLabelContainer1{
        display: flex;
        flex: 6;
    }
    .fomiContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10px;
    }
}